import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { Session } from 'next-auth'
import { UseQueryOptions } from '@tanstack/react-query'
export type CostumeSession = Session & {
  user: { firstName: string; lastName: string; token: string }
}
/**
 * Fetches session from `/api/auth/session`,
 * parses its JSON response, and returns it.
 * If there is no session, it returns `null`
 */
export function fetchSession(): Promise<CostumeSession | null>
export async function fetchSession() {
  const res = await fetch('/api/auth/session')
  const session = await res.json()
  if (Object.keys(session).length) {
    return session
  }
  return null
}

/**
 * React Query wrapper to retrieve `Session`.
 * Replaces `useSession` and `Provider` from `next-auth/client` in codebases
 * where you already use `react-query`.
 *
 * [`useSession`](https://next-auth.js.org/getting-started/client#usesession) |
 * [`Provider`](https://next-auth.js.org/getting-started/client#provider) |
 * [React Query](https://react-query.tanstack.com/guides/ssr#using-nextjs)
 */
export function useSession<R extends boolean = false>(params?: {
  /** If set to `true`, the returned session is guaranteed to not be `null` */
  required?: R
  /** If `required: true`, the user will be redirected to this URL, if they don't have a session */
  redirectTo?: string
  /** Configuration for `useQuery` */
  queryConfig?: UseQueryOptions<CostumeSession | null> | {}

  refetchOnMount?: boolean
}): [R extends true ? CostumeSession : CostumeSession | null, boolean]
export function useSession({
  required = false,
  redirectTo = '/api/auth/signin?error=SessionExpired',
  queryConfig = {},
  refetchOnMount = false,
} = {}) {
  const router = useRouter()
  const query = useQuery(['session'], fetchSession, {
    ...queryConfig,
    async onSettled(data, error) {
      // @ts-ignore
      if (queryConfig.onSettled) queryConfig.onSettled(data, error)
      if (data || !required) return
      await router.push(redirectTo)
    },
    refetchOnMount: refetchOnMount,
  })
  return [query.data, query.status === 'loading']
}
