import Script from 'next/script'
import { useMemo } from 'react'
import { ClientOnly } from '~/elements/ClientOnly/ClientOnly'
import { CostumeSession } from '~/hooks/useSession'
import { getCioUserId } from '~/lib/cioHelpers'

type CioUserScriptProps = {
  session: CostumeSession | null
}

export const CioUserScript: React.FC<CioUserScriptProps> = ({ session }) => {
  const userId = useMemo(() => getCioUserId(session?.user?.email), [session])

  return (
    <ClientOnly>
      {userId && (
        <Script
          id="cio-user"
          dangerouslySetInnerHTML={{
            __html: `window.cnstrcUserId = "${userId}";`,
          }}
        />
      )}
    </ClientOnly>
  )
}
