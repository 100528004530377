import {
  CustomerCartQuery,
  MergeCartsDocument,
  useCreateEmptyCartMutation,
  useCustomerCartQuery,
  useFetchCartHeaderQuery,
  useValidateCartQuery,
} from '@magentoTypes'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import invariant from 'tiny-invariant'
import { CART_ID, DEFAULT_LOCALE } from '~/config/constants'
import { createMagentoClient } from '~/graphql/magentoClient'
import { fetchSession, useSession } from './useSession'
import Cookies from 'js-cookie'
import { getStoreCode } from '~/lib/getStoreCode'
import { createLanguageRegionLocale } from '~/lib/createLanguageRegionLocale'
import { useRouter } from 'next/router'
import { removeCartIdCookie } from '~/lib/removeCartIdCookie'
import { log } from '~/lib/log'
import { toast } from 'react-toastify'

export const getCartId = (): string => {
  try {
    const cartIdCookie = Cookies.get(CART_ID)
    invariant(typeof cartIdCookie === 'string')
    return cartIdCookie
  } catch (e) {
    return ''
  }
}

const setCartId = (id: string) => {
  Cookies.set(CART_ID, id, {
    /**
     * method from here
     * https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#how-to-make-the-cookie-expire-in-less-than-a-day
     *
     * 864000 is the same as configured in magento per https://brandung.atlassian.net/browse/BENUTARE-512
     */
    expires: new Date(new Date().getTime() + 864000 * 1000),
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'strict',
    path: '/',
  })
}

export const useCartId = (
  createCartSessionByDefault: boolean = true,
): [string, boolean, (merge?: boolean) => Promise<void>] => {
  const CARTID_CREATION_PROCESS_KEY = 'cartfetched'
  const VALIDATION_PROCESS_KEY = 'cartvalidated'

  const queryClient = useQueryClient()
  const router = useRouter()
  const [forceReset, setForceReset] = useState(false)
  const [session, loadingSession] = useSession()
  const finalLocale = router.locale ? createLanguageRegionLocale(router.locale) : DEFAULT_LOCALE
  const graphqlClient = createMagentoClient({
    queryClient,
    errorPolicy: 'ignore',
    locale: finalLocale,
  })
  const mutationClient = createMagentoClient({ queryClient, usePost: true, locale: finalLocale })
  const cartId = getCartId()

  const createEmptyCartMutation = useCreateEmptyCartMutation(mutationClient, {
    onSuccess: (data) => {
      if (data.createEmptyCart) {
        // set cartfetched after mutation ran to false so we can fetch a new cartID if needed
        queryClient.setQueryData([CARTID_CREATION_PROCESS_KEY], () => false)
        setCartId(data.createEmptyCart)
        queryClient.setQueryData([CART_ID], data.createEmptyCart)
        queryClient.setQueryData([VALIDATION_PROCESS_KEY], () => true)
        setForceReset(false)
      }
    },
    onError(error) {
      // set cartfetched after mutation ran to false so we can fetch a new cartID if needed
      queryClient.setQueryData([CARTID_CREATION_PROCESS_KEY], () => false)
      queryClient.setQueryData([VALIDATION_PROCESS_KEY], () => false)
      if (JSON.parse(JSON.stringify(error))?.response?.errors[0]?.message) {
        log(JSON.parse(JSON.stringify(error)).response.errors[0].message)
      }
    },
  })

  useValidateCartQuery(
    graphqlClient,
    {
      cartId,
    },
    {
      retry: false,
      enabled: createCartSessionByDefault && !!cartId,
      onSettled: async (data) => {
        if (data?.cart?.id) {
          setCartId(data.cart.id)
          queryClient.setQueryData([CART_ID], data.cart.id)
          queryClient.setQueryData([VALIDATION_PROCESS_KEY], () => true)
        } else {
          const isCartValidated = queryClient.getQueryData([VALIDATION_PROCESS_KEY])
          const isCartFetched = queryClient.getQueryData([CARTID_CREATION_PROCESS_KEY])
          const freshSession = await fetchSession()
          if (
            createCartSessionByDefault &&
            !!!isCartFetched &&
            !!!isCartValidated &&
            !createEmptyCartMutation.isLoading &&
            !freshSession?.user.token
          ) {
            removeCartIdCookie()
            queryClient.setQueryData([VALIDATION_PROCESS_KEY], () => false)
            setForceReset(true)
          }
        }
      },
    },
  )

  // first try to get the logged in customer's cart
  const { isLoading: isCustomerCartInProgress, data } = useCustomerCartQuery(
    graphqlClient,
    undefined,
    {
      retry: false,
      enabled: createCartSessionByDefault && !loadingSession && !!session,
      onSettled(data) {
        if (data) {
          setCartId(data.customerCart.id)
          // we set this to the 'cartId' query so we can access is when we used a guest cart
          queryClient.setQueryData([CART_ID], data.customerCart.id)
        } else {
          const isCartFetched = queryClient.getQueryData([CARTID_CREATION_PROCESS_KEY])
          if (
            createCartSessionByDefault &&
            !!!isCartFetched &&
            !createEmptyCartMutation.isLoading &&
            !cartId
          ) {
            removeCartIdCookie()
            queryClient.setQueryData([VALIDATION_PROCESS_KEY], () => false)
            setForceReset(true)
          }
        }
      },
    },
  )

  // resets the cart ID
  const reset = async (merge?: boolean) => {
    const oldID = getCartId()
    removeCartIdCookie()
    queryClient.invalidateQueries([useCustomerCartQuery.getKey({}), 'session', CART_ID])
    const freshSession = await fetchSession()
    const token = session?.user.token || freshSession?.user.token
    // if user is logged in there might be a chance we want to merge the old and new cart
    if (merge && !loadingSession && token) {
      try {
        invariant(process.env.NEXT_PUBLIC_STORE_CODE, 'No NEXT_PUBLIC_STORE_CODE')
        // we're using this manual request bc the refetch method
        // does not get the memo about the new authorization header in time
        const headers = {
          Authorization: `Bearer ${token}`,
          Store: getStoreCode({ locale: finalLocale }) ?? 'benuta_de',
        }
        const data = await graphqlClient.request<CustomerCartQuery>(
          useCustomerCartQuery.document,
          undefined,
          headers,
        )
        const newCartId = data?.customerCart.id
        if (newCartId) {
          await mutationClient.request(
            MergeCartsDocument,
            {
              sourceCartId: oldID,
              destinationCartId: newCartId,
            },
            headers,
          )

          setCartId(data.customerCart.id)
          queryClient.setQueryData([CART_ID], data.customerCart.id)
          await queryClient.invalidateQueries([useFetchCartHeaderQuery.getKey({ cartId: oldID })])
        }
      } catch (error) {
        console.error(error)
      }
    } else if (merge && !freshSession?.user.token) {
      console.error('Could not get fresh session')
    }
  }

  useEffect(() => {
    // enables debugging of cookie process locally
    if (process.env.NODE_ENV === 'development') {
      ;(window as any).__resetCookie__ = removeCartIdCookie
    }
  }, [])

  useEffect(() => {
    // get the cartfetched variable from the queryClient
    // we are using react query here as a global state to prevent
    // fetching more than one cartID per session
    const isCartFetched = queryClient.getQueryData([CARTID_CREATION_PROCESS_KEY])
    const currentCartId = getCartId()
    if (
      createCartSessionByDefault &&
      !isCartFetched &&
      !session &&
      !loadingSession &&
      !createEmptyCartMutation.isLoading &&
      currentCartId.length === 0
    ) {
      // set cartfetched to true so we don't fetch more than one cartID per session
      queryClient.setQueryData([CARTID_CREATION_PROCESS_KEY], () => true)
      createEmptyCartMutation.mutate({})
    }
  }, [
    createCartSessionByDefault,
    loadingSession,
    createEmptyCartMutation,
    session,
    queryClient,
    forceReset,
  ])

  const { data: cartIdData } = useQuery([CART_ID], () => getCartId())

  if (data?.customerCart?.id && session) {
    return [data.customerCart.id, false, reset]
  } else {
    const isValidationInProgress = queryClient.getQueryData([VALIDATION_PROCESS_KEY])
    const isInProgress =
      !!!isValidationInProgress ||
      createEmptyCartMutation.isLoading ||
      (!!session && isCustomerCartInProgress)
    return [isInProgress ? '' : (cartIdData as string), isInProgress, reset]
  }
}
