import Cookies from 'js-cookie'
import { CART_ID } from '~/config/constants'
import { log } from './log'

const isDev = process.env.NODE_ENV === 'development'

export const removeCartIdCookie = () => {
  const cookie = Cookies.get(CART_ID)

  if (cookie) {
    log('found cookie')
    Cookies.remove(CART_ID, { path: '/' })
    log('removed cart id cookie')
  }
}
